<template>
  <div class="uk-padding-large position-relative uk-grid" uk-grid="" id="contentTop">
    <div class="uk-width-4-5@m">
      <div class="uk-card-default rounded p-4">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0 p-0">
          <div class="section-header pb-0">
            <div class="section-header-left">
              <input class="mt-3" type="text" placeholder="İçeriklerde Ara" v-model="search" />
            </div>
            <div class="section-header-right">
              <div class="btn-group bootstrap-select ml-3">
                <multiple-select-input :model.sync="selectedSort" :multiple="false" :required="false" :searchable="false"
                  :showLabels="false" :allow-empty="false" :values="sortOptions" @select="sortOrder" />
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive mt-4">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">İçerik Adı</th>
                <th scope="col">Türü</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <!--learning item-->
              <tr v-for="(item, index) in formatteditems">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <i style="font-size: 1.5rem" class="uil-book-open"></i>
                    </div>
                    <div class="media-body ml-4">
                      <span class="name h6 mb-0 text-sm">{{ item.title }}</span>
                      <small class="d-block font-weight-bold"></small>
                    </div>
                  </div>
                </th>
                <td>{{ $t(item.name) }}</td>
                <td class="text-right">
                  <!-- Actions -->
                  <div class="actions ml-3">
                    <template v-if="selected_items.includes(item.id)">
                      <button class="btn btn-sm btn-success" style="color: #fff"
                        @click="removeEducationFromContent(item)">
                        <i class="uil-check"></i>
                        Eklendi
                      </button>
                    </template>
                    <template v-else>
                      <button @click="$emit('addNewEducation', item)" class="btn btn-sm btn-primary" style="color: #fff">
                        <i class="uil-plus"></i> Ekle
                      </button>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" @changePage="setPage($event)"></pagination>

      </div>
    </div>

    <div class="uk-width-1-5@m">
      <div class="sidebar-filter card p-2 uk-sticky" uk-sticky="offset:50 ; media : @s: bottom: true">
        <div class="sidebar-filter-contents">
          <h4 style="text-transform: capitalize"><i class="il-filter"></i> Filtrele</h4>
          <label>
            <input class="uk-radio" type="radio" name="filters" @change="setType" :data-id="'all'" data-type="all"
              checked />
            <span style="margin-left:6px">
              {{ $t("general.all") }}
            </span>
          </label>
          <ul class="sidebar-filter-list uk-accordion" uk-accordion="multiple: true">
            <li class="uk-open">
              <a class="uk-accordion-title" href="#"> Türüne Göre </a>
              <div class="uk-accordion-content" aria-hidden="false">
                <div class="uk-form-controls">

                  <label v-for="item in formattedfilters.file_type" v-if="item.contents_count > 0">
                    <input class="uk-radio" type="radio" name="filters" :data-id="item.id" data-type="file_type_id"
                      @change="setType" />
                    <span class="test">
                      {{ item.name }}<span> ({{ item.contents_count }}) </span>
                    </span>
                  </label>
                  <label v-for="item in formattedfilters.content_type" v-if="item.contents_count > 0">
                    <input class="uk-radio" type="radio" name="filters" :data-id="item.id" data-type="content_type_id"
                      @change="setType" />
                    <span class="test">
                      {{ item.name }}<span> ({{ item.contents_count }}) </span>
                    </span>
                  </label>
                </div>
              </div>
            </li>

            <li class="uk-open">
              <a class="uk-accordion-title" href="#"> Kategori Etiketine Göre </a>
              <div class="uk-accordion-content" aria-hidden="false">
                <div class="form-group">
                  <label for="search-tag" style="font-size: 0.75rem"><i class="uil-search"></i> Etiket Arama</label>
                  <input type="text" class="form-control mb-0" id="search-tag" name="title" placeholder="Bir etiket yazın"
                    required="" autocomplete="off" />
                </div>
                <div class="uk-form-controls" style="overflow-y: scroll; height: 10rem">
                  <label v-for="item in formattedfilters.hashtags">
                    <input class="uk-radio" type="radio" name="filters" :data-id="item.name" data-type="hashtag"
                      @change="setType" />
                    <span class="test"> {{ item.name }} </span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="uk-sticky-placeholder" hidden="" style="height: 721px; margin: 0px 0px 20px"></div>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";

import module, {
  BASE_URL,
  MODULE_NAME,
  FILTERS_URL,
  FILTERS,
  GET_FILTERS,
  GET_ITEMS,
  ITEMS,
} from "@/core/services/store/content.module";

import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";

export default {
  name: "ContentForAssignment",
  components: { MultipleSelectInput, Pagination },
  props: {
    selected_items: {
      required: true,
      type: Array,
    },
    current_page: {
      required: false,
      type: String,
    },
    show_program: {
      required: false,
      type: Boolean,
      default: false,
    },
    show_exam: {
      required: false,
      type: Boolean,
      default: true,
    },
    show_survey: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      per_page: 10,
      timer: undefined,
      sort_by: "id",
      sort_desc: "desc",
      search: "",
      selectedSort: {
        id: 'created_at',
        name: 'Tarihe Göre'
      },
      filter: {
        orderBy: null,
        sort: null,
        hashtag: null,
        file_type_id: null,
        content_type_id: null,
      },
      pagination: null,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    sortOptions() {
      return [
        {
          id: "created_at",
          name: 'Tarihe Göre',
          sort: 'desc',
        },
        {
          id: "title",
          name: 'İsme Göre',
          sort: 'asc',
        },
        {
          id: "category",
          name: 'Kategoriye Göre',
          sort: 'asc',
        },
      ];
    },
    filters: {
      get() {
        return store.getters[MODULE_NAME + "/" + FILTERS];
      },
      set(value) {
      },
    },
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    formattedfilters: {
      get() {
        let results = [];
        let filters = this.filters;
        if (filters === null) return [];
        return filters;
      },
    },
    formatteditems: {
      get() {
        let results = [];
        let items = this.items.data;
        if (this.items.current_page !== undefined) {
          this.pagination = this.items;
        }

        if (items === null || undefined === items) {
          return [];
        }

        for (let i = 0; i < items.length; i++) {
          let currentItem = items[i];
          if (currentItem) {
            if (!this.current_page) {
              results.push({
                title: currentItem.title,
                name: currentItem.key_name,
                id: currentItem.id,
              });
            } else {
              if (currentItem.key_name !== "documents.program") {
                results.push({
                  title: currentItem.title,
                  name: currentItem.key_name,
                  id: currentItem.id,
                });
              }
            }
          }
        }
        return results;
      },
    },
  },
  methods: {
    scrollToTop() {
      document
        .getElementById("contentTop")
        .scrollIntoView({ behavior: "smooth" });
    },
    removeEducationFromContent(edu) {
      let self = this;
      self.$emit("removeEducation", edu);
    },
    filterResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          search: this.search,
          page: this.page,
          per_page: this.per_page,
          orderBy: this.filter.orderBy,
          sort: this.filter.sort,
          hashtag: this.filter.hashtag,
          file_type_id: this.filter.file_type_id,
          content_type_id: this.filter.content_type_id,
          program: this.show_program,
          exam: this.show_exam,
          survey: this.show_survey,
        },
      });
    },
    sortOrder(event) {
      this.page = 1;
      this.filter.orderBy = event.id;
      this.filter.sort = event.sort;
      this.filterResult();
    },
    setType(event) {
      let target = event.target;
      let id = target.getAttribute("data-id");
      let type = target.getAttribute("data-type");
      this.filter.file_type_id = this.filter.content_type_id = this.filter.hashtag = null;
      if (type == "hashtag") this.filter.hashtag = id;
      else if (type == "file_type_id") this.filter.file_type_id = id;
      else if (type == "content_type_id") this.filter.content_type_id = id;
      else if (type === 'all') {
        this.getItems();
      }
      type !== 'all' ? this.debouncedFilterType() : null
    },
    setPage(page) {
      this.page = page;
      this.scrollToTop();
      this.filterResult();
    },
    getContentFilters() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_FILTERS, {
        url: FILTERS_URL,
        filters: {
          program: this.show_program,
          exam: this.show_exam,
          survey: this.show_survey
        },
      });
    },
    getItems() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          program: this.show_program,
          exam: this.show_exam,
          survey: this.show_survey
        },
      });
    },
  },
  watch: {
    search: function (val) {
      this.setPage(1)
      this.debouncedFilterSearch();
    },
    filter: {
      handler(val) {
        this.setPage(1)
      },
      deep: true
    }
  },
  mounted() {
    this.getContentFilters();
    this.getItems();
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    this.debouncedFilterType = _.debounce(this.filterResult, 300);
  },
};
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>


<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
